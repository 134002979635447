<template>
  <v-card flat>
    <v-form v-model="form">
      <v-toolbar
        class="mt-2"
        dense
        flat
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon dense>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbs" />
        <v-spacer />
        <slot
          name="actions"
          :form="form"
          :fields="fields"
          :isEqual="isEqual"
        />
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-model="fields.name"
          :rules="[rules.required]"
          label="Name"
          filled
          outlined
          dense
        />
        <v-text-field
          v-model="fields.description"
          :rules="[]"
          label="Description"
          filled
          outlined
          dense
        />
        <v-text-field
          v-model="fields.price"
          :rules="[rules.required]"
          label="Price"
          filled
          outlined
          dense
        />
        <v-text-field
          v-model="fields.vat"
          :rules="[rules.required]"
          label="VAT"
          filled
          outlined
          dense
        />
      </v-card-text>
      <v-card-text>
        <ManyToManySelector
          v-model="fields.itemCustomizers"
          :company-id="fields.companyId"
          :establishment-id="fields.establishmentId"
          label="Customizers"
          :items="customizers.all()"
          item-text="name"
          item-value="id"
          relation="customizer"
          multiple
        />
      </v-card-text>
      <v-card-title>Customizers</v-card-title>
      <v-card-text>
        <RankList
          v-model="fields.itemCustomizers"
          item-text="customizer.name"
        />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'
import ManyToManySelector from '@/components/ManyToManySelector'
import RankList from '@/components/RankList'
import formMixin from '@/mixins/formMixin'

export default {
  name: 'ItemForm',
  components: { ManyToManySelector, RankList },
  mixins: [formMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('app', ['customizers'])
  }
}
</script>
